<template>
  <div>
    <div class="content">
      <el-table :data="List_Data" style="width: 100%">
        <el-table-column prop="content" label="检查项目"> </el-table-column>

        <el-table-column label="检查结果" v-if="type != 'standing' && type">
          <template slot-scope="scope">
            <div v-if="type == 'task'">
              <!-- {{scope.row.status}} -->
              <el-radio-group v-model="scope.row.status">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="2">否</el-radio>
              </el-radio-group>
            </div>
            <div v-else>
              {{ ["未检查", "是", "否"][scope.row.status] }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          v-for="(item, index) in users"
          :key="index"
          :prop="item.key"
          :label="`检查结果(${item.nickname})`"
        >
          <template slot-scope="scope">
            <div>{{ ["未检查", "是", "否"][scope.row[item.key]] }}</div>
          </template>
        </el-table-column>
      </el-table>

      <div style="text-align: center; margin: 20px 0" v-if="type == 'task'">
        <el-button @click="on_preserve()" type="primary">提交</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  evaluationtaskInfo,
  evaluationtaskSave,
} from "../../assets/request/api";
export default {
  data() {
    return {
      users: [],
      List_Data: [],
      type: "",
      task_id: "",
      eval_id: "",
    };
  },
  created() {
    let { id, task_id, type, log_id } = this.$route.query;
    this.type = type;
    this.eval_id = id;
    this.task_id = task_id;
    let List_From = {
      eval_id: id,
    };
    if (type == "standing") {
      List_From.log_id = log_id;
    } else {
      List_From.task_id = task_id;
    }

    evaluationtaskInfo(List_From).then((res) => {
      let { users, info } = res.data;
      this.List_Data = info.content;
      if (type == "standing" || !type) {
        this.users = users;
      }
      console.log(res);
    });
  },
  methods: {
    on_preserve() {
      let { List_Data, task_id, eval_id } = this;
      let data = [];

      List_Data.map((el) => {
        data.push({
          id: el.id,
          status: el.status,
        });
      });
      let from_Data = {
        task_id,
        eval_id,
        data,
      };

      evaluationtaskSave(from_Data).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          let that = this;
          that.$emit("way_tableData");
          setTimeout(function () {
            that.route_go();
          }, 1000);
        }
      });
    },
    //返回
    route_go() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.entirety {
  background: #ffffff;
  display: flex;
}
.title {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ebeef5;
}
.content {
  flex: 3;
  // & > div {
  //   display: flex;
  //   p {
  //     flex: 1;
  //   }
  // }
}
</style>